import React from 'react';
import { Button, Spinner } from 'reactstrap';
import classnames from "classnames";
import styled from 'styled-components';

type Props = {
    onClick?: () => void,
    className?: string,
    loading?: boolean,
    color?: string,
    children?: React.ReactNode,
    disabled?: boolean
}
const LoadingButton = ({ loading, children, ...rest }: Props) => {
    return (
        <Button {...rest} disabled={!!loading}>
            {loading && (
                <Spinner
                    className={classnames({
                        "position-relative": true,
                        visible: loading,
                        invisible: !loading
                    })}
                    size="sm"
                // type="grow"
                />
            )}
            {!loading && (
                <span
                    className={classnames({
                        invisible: loading,
                        visible: !loading
                    })}
                >
                    {children}
                </span>
            )}
        </Button>
    )
}

export default styled(LoadingButton)`
    background-color: #3A5579 ;
    border: 0px;
    margin: 15px;
    width: 50%;
    padding-top: 8px;
    padding-bottom: 8px;
    }
`