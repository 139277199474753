import React, { useEffect, useState } from 'react'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Container, Row, Input } from 'reactstrap';
import logo from '../assets/logo.png'

interface AskReasonModalnterface {
    networkShare: boolean
    modal: boolean
    toggle: () => void
    onConfirmUnsubscribe: (reason: Array<{ reason: string; answer?: string }>) => void
}

function AskReasonModal(props: AskReasonModalnterface) {
    const { modal, toggle, onConfirmUnsubscribe, networkShare } = props

    const deleteConfirmationFeedbackItems = [
        { reason: 'Privacy Concerns', followUpQuestion: null },
        { reason: 'Technical Issues', followUpQuestion: 'Kindly Explain Your Technical Issue(s):' },
        { reason: 'Poor User Experience', followUpQuestion: 'Kindly Explain: 🙂' },
        { reason: 'High Subscription Costs', followUpQuestion: null },
        { reason: 'Limited Content or Features', followUpQuestion: "What you'd like to see added?" },
        { reason: 'Switching To Another Service', followUpQuestion: "Kindly explain the reason for the switch:" },
        { reason: 'Other Reason', followUpQuestion: 'Kindly Explain: 😊' },
    ];
    const [deleteConfirmationItemsSelected, setDeleteConfirmationItemsSelected] = useState<Array<{ reason: string; answer?: string; }>>([]);

    const handleDeleteConfirmationItemSelect = (item: string) => {
        if (deleteConfirmationItemsSelected.some(obj => obj.reason === item)) {
            console.log(deleteConfirmationItemsSelected.filter(i => i.reason !== item))
            setDeleteConfirmationItemsSelected(
                deleteConfirmationItemsSelected.filter(i => i.reason !== item),
            );
        } else {
            setDeleteConfirmationItemsSelected([
                ...deleteConfirmationItemsSelected,
                { reason: item },
            ]);
        }
    };

    const addAnswer = (reason_: string, answer: string) => {
        const newdeleteConfirmationItemsSelected = deleteConfirmationItemsSelected.map(values => {
            const { reason } = values
            if (reason == reason_) {
                return { reason, answer }
            }
            return values
        })
        setDeleteConfirmationItemsSelected(newdeleteConfirmationItemsSelected)
    }
    return (
        <Modal isOpen={modal} toggle={toggle}>
            <ModalBody>
                <Container>
                    <div style={{ margin: 5, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <img src={logo} alt="React Logo" style={{ width: 50, height: 50 }} />
                    </div>
                    <div style={{ margin: 5, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <h5 style={{ textAlign: 'center' }}>
                            Please select from the following the reason why you wanted
                            to unsubscribe{networkShare && " to our network share"}.
                        </h5>
                    </div>
                    <div style={{ margin: 5, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <h5 style={{ textAlign: 'center' }}>
                            You can choose two or more reasons.
                        </h5>
                    </div>
                    <Container>
                        {
                            deleteConfirmationFeedbackItems.map((data) => {
                                const { reason, followUpQuestion } = data
                                return (
                                    <Container>
                                        <div style={{ display: 'flex', gap: 10, justifyContent: 'start', margin: 10 }}>
                                            <input type="checkbox" onChange={() => {
                                                handleDeleteConfirmationItemSelect(reason);
                                            }} />
                                            <div style={{ fontSize: '14px' }}>
                                                {reason}
                                            </div>
                                        </div>
                                        {
                                            (deleteConfirmationItemsSelected.some(obj => obj.reason == reason) && followUpQuestion) &&
                                            <textarea onChange={(e) => addAnswer(reason, e.target.value)} style={{ width: '100%', height: '100px' }} placeholder={followUpQuestion} />
                                        }
                                    </Container>
                                )
                            })
                        }
                    </Container>
                    <div style={{ display: 'flex', margin: 10, justifyContent: 'space-around' }}>
                        <Button onClick={toggle} style={{ backgroundColor: '#3A5579' }}> Cancel</Button>
                        <Button disabled={deleteConfirmationItemsSelected.length < 2} onClick={async () => {
                            onConfirmUnsubscribe(deleteConfirmationItemsSelected)
                        }} style={{ backgroundColor: 'red' }}> Confirm</Button>
                    </div>
                </Container>

            </ModalBody>
        </Modal>
    )
}

export default AskReasonModal