import { useState } from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter, Input, Alert } from "reactstrap";
import ReactFlagsSelect from 'react-flags-select';

import {
    CardElement,
    useStripe,
    useElements,
} from '@stripe/react-stripe-js';
import LoadingButton from "../LoadingButton";
import BillingInfo from "../../interfaces/BillingInfo";
import PaymentHandler from "../../classes/PaymentHandler";
import { PaymentIntentResult, StripeCardElement } from "@stripe/stripe-js";
import Coupon from "../../interfaces/Coupon";
import PaymentIntent from "../../interfaces/PaymentIntent";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";

type Props = {
    isOpen: boolean,
    userId: number | undefined,
    customerId: string | undefined,
    email: string | undefined,
    phone: string | undefined,
    productId: string | undefined,
    serverUrl: string,
    country: string,
    toggle?: () => void,
    onPaymentSuccess: (subscriptionId: string, paymentResult: PaymentIntentResult) => void
}

const StripePaymentModal = (props: Props) => {
    const [alert, setAlert] = useState('');
    const [paying, setPaying] = useState(false);
    const [billingInfo, setBillingInfo] = useState<BillingInfo>({ fullName: '', city: '', address1: '', address2: '', state: '', zipCode: '', country: props.country, coupon: '' });
    const [couponDetails, setCouponDetails] = useState<Coupon | null>(null);
    const { executeRecaptcha } = useGoogleReCaptcha();
    const stripe = useStripe();
    const elements = useElements();

    const onClickPayNow = () => {
        if (!stripe || !elements) {
            return;
        }
        if (!billingInfo.fullName) {
            setAlert('Invalid full name');
            return;
        }
        if (!billingInfo.city) {
            setAlert('Invalid city');
            return;
        }
        if (!billingInfo.address1) {
            setAlert('Invalid address');
            return;
        }
        if (!billingInfo.state) {
            setAlert('Invalid state');
            return;
        }
        if (!billingInfo.zipCode) {
            setAlert('Invalid zip code');
            return;
        }
        fetchPaymentIntentClientSecret();
    }

    const fetchPaymentIntentClientSecret = async () => {
        if (!executeRecaptcha) {
            setAlert('Recaptcha challenge is not ready yet');
            return;
        }
        const token = await executeRecaptcha('payment');
        if (!token) {
            setAlert('Solving captcha is failed');
            return;
        }
        if (!props.userId) {
            setAlert('Invalid user id detected')
            return;
        }
        if (!props.productId) {
            setAlert('Invalid productId');
            return;
        }
        if (!stripe || !elements) {
            setAlert('Stripe elements not ready yet');
            return;
        }
        
        try {
            setPaying(true);
            const paymentHandler = new PaymentHandler<PaymentIntent>(props.serverUrl);
            const paymentIntent = await paymentHandler.fetchPaymentIntentClientSecret(props.userId, props.productId, couponDetails?.name);
            
            if (paymentIntent.clientSecret) {
                const paymentResult = await stripe?.confirmCardPayment(paymentIntent.clientSecret, { 
                    payment_method: {
                        card: elements.getElement('card') as StripeCardElement,
                        billing_details: {
                            address: {
                                city: billingInfo.city, 
                                country: billingInfo.country, 
                                line1: billingInfo.address1, 
                                line2: billingInfo.address2, 
                                state: billingInfo.state 
                            },
                            name: billingInfo.fullName,
                            email: props.email,
                            phone: props.phone,
                        }
                    },
                    setup_future_usage: 'off_session'
                })
                
                setPaying(false);
                if (paymentResult.error) {
                    setAlert(paymentResult.error.message ?? 'Payment error');
                    return;
                }
                if (paymentResult.paymentIntent) {
                    props.onPaymentSuccess(paymentIntent.subscriptionId, paymentResult);
                }
            }
        } catch(e) {
            if (e instanceof Error) {
                setAlert(e.message);
            } else if (typeof e === 'string') {
                setAlert(e);
            }
            setPaying(false);
        }
    }

    const getCouponDetails = async () => {
        if (billingInfo.coupon) {
            try {
                const paymentHandler = new PaymentHandler<Coupon>(props.serverUrl);
                const coupon = await paymentHandler.getCouponDetails(billingInfo.coupon);
                setCouponDetails(coupon);
                setAlert('');
            } catch(e) {
                if (e instanceof Error) {
                    setAlert(e.message);
                } else if (typeof e === 'string') {
                    setAlert(e);
                }
            }
        } else {
            setCouponDetails(null);
        }
    }

    const getSubscriptionFee = () => {
        if (props.productId === process.env.REACT_APP_SLOT_1) {
            if (props.country === 'US') {
                return 2999
            } else if (props.country === 'GB') {
                return 2339
            }
        } else if (props.productId === process.env.REACT_APP_SLOT_2) {
            if (props.country === 'US') {
                return 5499
            } else if (props.country === 'GB') {
                return 4289
            }
        } else if (props.productId === process.env.REACT_APP_SLOT_3) {
            if (props.country === 'US') {
                return 7499
            } else if (props.country === 'GB') {
                return 5849
            }
        } else if (props.productId === process.env.REACT_APP_SLOT_4) {
            if (props.country === 'US') {
                return 8999
            } else if (props.country === 'GB') {
                return 7019
            }
        } else if (props.productId === process.env.REACT_APP_SLOT_5) {
            if (props.country === 'US') {
                return 10499
            } else if (props.country === 'GB') {
                return 8189
            }
        }
        return 0;
    }

    const getTotalPrice = () => {
        let totalPrice = getSubscriptionFee();
        if (couponDetails) {
            if(couponDetails.percent_off) totalPrice = totalPrice * (100 - couponDetails.percent_off) / 100;
            if(couponDetails.amount_off) totalPrice = totalPrice - couponDetails.amount_off;
        }
        return totalPrice;
    }

    const getCurrency = () => {
        if (props.country === 'GB') {
            return '£';
        }
        return '$';
    }

    return (
        <Modal isOpen={props.isOpen} toggle={props.toggle} centered>
            <ModalHeader className='justify-content-center'>
                Posh Sidekick Card Subscription
            </ModalHeader>
            <ModalBody>
                <small><strong>Billing Info</strong></small>
                <Input placeholder="Full Name" className="mt-2" value={billingInfo.fullName} onChange={e => setBillingInfo(prev => ({ ...prev, fullName: e.target.value }))} />
                <Input placeholder="City" className="mt-2" value={billingInfo.city} onChange={e => setBillingInfo(prev => ({ ...prev, city: e.target.value }))} />
                <Input placeholder="Address 1" className="mt-2" value={billingInfo.address1} onChange={e => setBillingInfo(prev => ({ ...prev, address1: e.target.value }))} />
                <Input placeholder="Address 2 (Optional)" className="mt-2" value={billingInfo.address2} onChange={e => setBillingInfo(prev => ({ ...prev, address2: e.target.value }))} />
                <div className="d-flex justify-content-between mt-2">
                    <Input placeholder="State"  value={billingInfo.state} onChange={e => setBillingInfo(prev => ({ ...prev, state: e.target.value }))} />
                    <Input placeholder="Zip Code" className="ms-2" value={billingInfo.zipCode} onChange={e => setBillingInfo(prev => ({ ...prev, zipCode: e.target.value }))}/>
                </div>
                <ReactFlagsSelect 
                    className="mt-2"
                    selected={billingInfo.country}
                    onSelect={(country) => setBillingInfo(prev => ({ ...prev, country }))}
                />
                <Input placeholder="Coupon Code (Optional)" className="mt-2" value={billingInfo.coupon} onChange={e => setBillingInfo(prev => ({ ...prev, coupon: e.target.value }))} 
                    onBlur={getCouponDetails} />
                <div className="mt-2">
                    <small><strong>Card Details</strong></small>
                </div>
                <CardElement 
                    className='mt-2 bg-white p-2 border text-muted rounded'
                    options={{
                        hidePostalCode: true,
                        style: {
                            base: {
                                fontSize: '16px',
                                color: '#8898aa',
                                fontFamily: 'Poppins',
                                '::placeholder': {
                                    color: '#8898aa',
                                }
                            }
                        }
                    }} />
                <hr />
                <small><strong>Order Summary</strong></small>
                <div className="d-flex justify-content-between mt-2">
                    <small>Subscription Fee</small>
                    <small>{getCurrency()}{(getSubscriptionFee() / 100).toFixed(2)}</small>
                </div>
                {
                    !!couponDetails &&
                    <div className="d-flex justify-content-between mt-2">
                        <small>Coupon Discount</small>
                        {couponDetails.percent_off && <small>-{couponDetails.percent_off}%</small>}
                        {couponDetails.amount_off && <small>- ${couponDetails.amount_off}</small>}
                    </div>
                }
                <div className="d-flex justify-content-between mt-2">
                    <small>Total</small>
                    <small>{getCurrency()}{(getTotalPrice() / 100).toFixed(2)}</small>
                </div>
                {
                    !!alert && <Alert className="mt-2" color="danger" toggle={() => setAlert('')}>{alert}</Alert>
                }
            </ModalBody>
            <ModalFooter className="justify-content-center">
                <LoadingButton onClick={onClickPayNow} loading={paying} disabled={paying}>Pay Now</LoadingButton>
            </ModalFooter>
        </Modal>
    )
}

export default StripePaymentModal;