import { useEffect, useMemo, useState } from "react";
import { Col, Container, Row, Card, CardHeader, CardBody, CardFooter, Alert, CardTitle, Button, CardText } from "reactstrap";
import { ReactComponent as Logo } from '../assets/logo_onboard.svg';
import ReactSlider from "react-slider";
import LoadingButton from "../components/LoadingButton";
import StripePaymentModal from "../components/payment/StripePaymentModal";
import { loadStripe, PaymentIntentResult } from '@stripe/stripe-js';
import { Elements } from "@stripe/react-stripe-js";
import { useLocation, useNavigate } from "react-router-dom";
import UserInfo from "../interfaces/UserInfo";
import PoshAccount from "../interfaces/PoshAccount";
import PaymentHandler from "../classes/PaymentHandler";
import Subscription from "../interfaces/Subscription";
import moment from "moment";
import _, { upperFirst } from 'lodash';
import AlertModal from "../components/AlertModal";
import ChoosePaymentModal from "../components/payment/ChoosePaymentModal";
import PaypalPaymentModal from "../components/payment/PaypalPaymentModal";
import axios from "axios";
import AskReasonModal from "../components/AskReasonModal";
import { usePoshSideKick } from "../poshSideKickStorage";
import { OverAllSubscription, PaymentDetails, PaymentMethodDetails } from "../interfaces/OverAllSubscription";
import { BillingDetailsInformation } from "../interfaces/BillingDetails";
import { useWindowSize } from "usehooks-ts";
import { BsFillTrashFill } from "react-icons/bs"


const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISH_KEY as string);
const Payment = () => {
    const [iOSAlert, setIOSAlert] = useState(true);
    const [alert, setAlert] = useState('');
    const [count, setCount] = useState(1); // 1 - 29.99 2 - 49.99 3 - 74.99 4 - 99 5 - 124.99
    const [country, setCountry] = useState('US');
    const [openPaymentChooser, setOpenPaymentChooser] = useState(false);
    const [openStripePayment, setOpenStripePayment] = useState(false);
    const [openPaypalPayment, setOpenPaypalPayment] = useState(false);
    const [userInfo, setUserInfo] = useState<UserInfo | null>(null);
    const [pmAccounts, setPMAccounts] = useState<PoshAccount[]>([]);
    const [selectedPmaccount, setselectedPmaccount] = useState<string | null>(null)
    const [stripeSubscription, setStripeSubscription] = useState<Subscription | null>(null);
    //TODO: show paypal subscription if it is done 
    const [upgrading, setUpgrading] = useState(false);
    const [openCancelAlert, setOpenCancelAlert] = useState(false);
    const [unsubscribing, setUnsubscribing] = useState(false);
    const [unsubscribeErr, setUnsubscribeErr] = useState('');
    const [networkShareSubscription, setNetworkShareSubscription] =
        useState<any>(null);
    const [subscriptionV2, setsubscriptionV2] = useState<OverAllSubscription | null>(null)
    const [paymentDetails, setpaymentDetails] = useState<Array<BillingDetailsInformation> | null>(null)
    const location = useLocation();
    const { changeBillingDetails } = usePoshSideKick()
    const navigate = useNavigate();

    useEffect(() => {
        const state = location.state as { user: UserInfo, accounts: PoshAccount[] };
        const userInfo = state?.user;
        const pmAccounts = state?.accounts;
        if (userInfo) {
            setUserInfo(userInfo);
            if (userInfo.slotCount) {
                setCount(userInfo.slotCount);
            }
        }
        if (pmAccounts) {
            setPMAccounts(pmAccounts);
            pmAccounts.map(data => {
                if (data.selected === 1) {
                    setselectedPmaccount(data.pmUserId)
                }
            })
        }

        if (location.search.includes("paypal")) {
            setOpenPaypalPayment(true)
            if (location.search.includes("count")) setCount(parseInt(location.search.replace(/.*count=/, "")))
            else setCount(1)
        }

        if (!userInfo?.id || !userInfo?.serverUrl) {
            navigate('/', { replace: true });
        }
    }, [location.state, location.search, navigate]);

    useEffect(() => {
        const getSubscription = async () => {
            try {
                if (!userInfo?.id) return;
                const paymentHandler = new PaymentHandler<Subscription>(userInfo!.serverUrl!);
                const subscription = await paymentHandler.getSubscription(userInfo?.id)
                const subscription2 = await paymentHandler.getSubscriptionV2(userInfo?.id)
                const paymentMethods = await paymentHandler.getPaymentDetails(userInfo?.id)
                if (paymentMethods) {

                    const uniqueCardsLast4: Array<string> = []

                    let uniqueCards = paymentMethods.filter(obj => {
                        if (uniqueCardsLast4.includes(obj.card.last4)) {
                            return false;
                        }
                        uniqueCardsLast4.push(obj.card.last4)
                        return true;
                    });
                    const rearrange = uniqueCards.sort((a, b) => {
                        if (a.isDefault) return -1;
                        if (b.isDefault) return 1;
                        return 0;
                    })
                    setpaymentDetails(rearrange)
                    changeBillingDetails(rearrange)
                }
                if (selectedPmaccount) {
                    const networkShare: any = await paymentHandler.getNetworkShareSubscrition(selectedPmaccount)
                    setNetworkShareSubscription(networkShare)
                }
                if (subscription.subscriptionId) {
                    setStripeSubscription(subscription)
                }
                if (subscription2) {
                    setsubscriptionV2(subscription2)
                }
            } catch (e) {
                if (e instanceof Error) {
                    setAlert(e.message);
                } else if (typeof e === 'string') {
                    setAlert(e);
                }
            }
        }

        if (userInfo) {
            getSubscription();
        }
    }, [userInfo]);

    useEffect(() => {
        // Get current location
        axios.get("https://geolocation-db.com/json/").then(resp => {
            const { country_code } = resp.data;
            setCountry(country_code);
        })
    }, []);

    const onChangeSlider = (newVal: number, newIndex: number) => {
        setCount(newVal);
    }

    const onClkPayNow = async () => {
        if (readTerms === false) {
            setAlert('Please read and accept the terms of service and privacy policy.')
            return;
        }
        if (!stripeSubscription) {
            setOpenStripePayment(true);
        } else {
            if (count === stripeSubscription?.slotCount) {
                setAlert('This is the plan you already purchased.');
                return;
            }
            if (count < pmAccounts.length) {
                setAlert('You need to unlink some poshmark closets first.')
                return;
            }
            if (!stripeSubscription.subscriptionId) {
                return;
            }
            const productId = getProductId();
            if (!productId) {
                return;
            }
            try {
                setUpgrading(true);
                const paymentHandler = new PaymentHandler<Subscription>(userInfo!.serverUrl!);
                const upgradedSub = await paymentHandler.upgradeSubscription(stripeSubscription.subscriptionId, productId);
                setStripeSubscription(prev => ({
                    ...prev,
                    subscriptionId: upgradedSub.subscriptionId,
                    amountPaid: getPrice(),
                    renewAt: addDaysToDate(new Date(), 30).toString(),
                    slotCount: count
                }));
            } catch (e) {
                if (e instanceof Error) {
                    setAlert(e.message);
                } else if (typeof e === 'string') {
                    setAlert(e);
                }
            } finally {
                setUpgrading(false);
            }
        }
    }

    const addDaysToDate = (date: Date, days: number) => {
        return new Date(date.getTime() + days * 24 * 60 * 60 * 1000);
    }

    const getPrice = () => {
        // If country not US or GB set to US
        if (country !== 'US' && country !== 'GB') {
            setCountry('US');
        }


        if (count === 1) {
            if (country === 'US') {
                return 2999
            } else if (country === 'GB') {
                return 2339
            }
        } else if (count === 2) {
            if (country === 'US') {
                return 5499
            } else if (country === 'GB') {
                return 4289
            }
        } else if (count === 3) {
            if (country === 'US') {
                return 7499
            } else if (country === 'GB') {
                return 5849
            }
        } else if (count === 4) {
            if (country === 'US') {
                return 8999
            } else if (country === 'GB') {
                return 7019
            }
        } else if (count === 5) {
            if (country === 'US') {
                return 10499
            } else if (country === 'GB') {
                return 8189
            }
        }

        return 0;
    }

    const getCurrency = () => {
        if (country === 'GB') {
            return '£';
        }
        return '$';
    }

    const getProductId = () => {
        if (count === 1) {
            return process.env.REACT_APP_SLOT_1
        } else if (count === 2) {
            return process.env.REACT_APP_SLOT_2
        } else if (count === 3) {
            return process.env.REACT_APP_SLOT_3
        } else if (count === 4) {
            return process.env.REACT_APP_SLOT_4
        } else if (count === 5) {
            return process.env.REACT_APP_SLOT_5
        }
        return undefined;
    }

    const onPaymentSuccess = (subscriptionId: string, paymentResult: PaymentIntentResult) => {
        setOpenStripePayment(false);
        setStripeSubscription({
            subscriptionId,
            subscribedPlan: 'monthly',
            amountPaid: paymentResult.paymentIntent?.amount,
            slotCount: count,
            paymentDetails: {
                card: {
                    brand: 'N/A',
                    last4: '****'
                }
            },
            renewAt: addDaysToDate(new Date(), 30).toString()
        });
    }

    const getButtonTitle = () => {
        if (!stripeSubscription) {
            return 'Buy Now';
        } else {
            if (!stripeSubscription.slotCount) {
                return 'Buy Now';
            }

            if (count < stripeSubscription.slotCount) {
                return 'Downgrade';
            } else {
                if (count === stripeSubscription.slotCount) {
                    return 'Current Subscription';
                } else {
                    return 'Upgrade';
                }
            }
        }
    }

    const cancelSubscription = () => {
        setShowModal(true)
    }

    const onConfirmUnsubscribe = async (reason: Array<{ reason: string; answer?: string }>) => {
        try {
            setUnsubscribing(true);
            if (!userInfo?.id) {
                return;
            }
            const paymentHandler = new PaymentHandler(userInfo!.serverUrl!);
            await paymentHandler.cancelSubscription(userInfo?.id, reason);
            setUnsubscribing(false);
            setStripeSubscription(null);
            setOpenCancelAlert(false);
        } catch (e) {
            if (e instanceof Error) {
                setUnsubscribeErr(e.message);
            } else if (typeof e === 'string') {
                setUnsubscribeErr(e);
            }
        }
    }

    const onConfirmUnsubscribeNetworkShare = async (reason: Array<{ reason: string; answer?: string }>) => {
        try {
            setUnsubscribing(true);
            if (!userInfo?.id) {
                return;
            }
            const paymentHandler = new PaymentHandler(userInfo!.serverUrl!);
            if (selectedPmaccount) {
                await paymentHandler.cancelSubscriptionNetworkShare(selectedPmaccount, reason);
            } else {
                setUnsubscribeErr('No selected poshmark accont')
            }
            setUnsubscribing(false);
            setStripeSubscription(null);
            setOpenCancelAlert(false);
        } catch (e) {
            if (e instanceof Error) {
                setUnsubscribeErr(e.message);
            } else if (typeof e === 'string') {
                setUnsubscribeErr(e);
            }
        }
    }


    const firstColumn = ['Self Shares', 'Party Shares', 'Return Shares', 'Community Shares', 'Safety pause']
    const secondColumn = ['Unfollow', 'Follow Backs', 'Follow Closets', 'Randomization']
    const secondRow = ['Closet Snapshot', 'Background Remover', 'Relisting of Older Items', 'Automated Silent Auctions', 'Activity Monitoring and Insights', 'Custom Categories for Sharing, Live Auction and Offers']
    const otherFeatures = ['Sales Report', 'Reserve Pricing', 'No Captcha Hassle', 'Share Speed Option']
    const [readTerms, setreadTerms] = useState(false);
    const [showModal, setShowModal] = useState(false)
    const [showModalUnsubscribed, setShowModalUnsubscribed] = useState(false)
    const { width, height } = useWindowSize()
    const isMobile = width < 500

    return (
        <Elements stripe={stripePromise} options={{ fonts: [{ cssSrc: 'https://fonts.googleapis.com/css?family=Poppins:400' }] }}>
            <AskReasonModal networkShare={false} onConfirmUnsubscribe={onConfirmUnsubscribe} modal={showModal} toggle={() => setShowModal(!showModal)} />
            <AskReasonModal networkShare={true} onConfirmUnsubscribe={onConfirmUnsubscribeNetworkShare} modal={showModalUnsubscribed} toggle={() => setShowModalUnsubscribed(!showModalUnsubscribed)} />
            <Row xs={isMobile ? 1 : 3} style={{ margin: 10 }}>
                <Col style={{ marginRight: 15, marginLeft: 15, marginTop: 5, display: isMobile ? "none" : 'block' }} className="my-5">
                    {
                        (paymentDetails && paymentDetails.length > 0 && userInfo) &&
                        paymentDetails.map((payment) => {
                            return <CardPayment addPayment={() => setOpenStripePayment(true)} userInfo={userInfo} paymentsDetail={payment} AllPaymentDetails={paymentDetails} />
                        })
                    }
                {
                    <Button onClick={() => setOpenStripePayment(true)} style={{ backgroundColor: '#3A5579', width: '90%', marginTop: 15 }} className="mb-0">
                        Add New Payment Method
                    </Button>
                }

                </Col>

                <Col className="my-5">
                    <Row >
                        <Col lg='6' style={{ width: "100%" }} className='mx-auto'>
                            {
                                userInfo?.device === 'iphone' && iOSAlert &&
                                <Alert className="mt-2" toggle={() => setIOSAlert(false)}>
                                    <strong>If you are an iphone user, please keep these in mind.</strong>{<br />}{<br />}
                                    1. If you already subscribed through in-app purchase, please unsubscribe it first and try here.{<br />}{<br />}
                                    2. If you subscribed through this page, you will be considered as a subscriber and you can continue using the app.{<br />}{<br />}
                                    3. Manage your subscription here if you subscribed through this page
                                </Alert>
                            }
                            <Card>
                                <CardHeader style={{ fontSize: '20px', fontWeight: 'bold' }}>
                                    POSHMARK AUTOMATION
                                </CardHeader>
                                <CardBody>
                                    <h5 style={{ color: '#3A5579' }}>{getCurrency()}{(getPrice() / 100).toFixed(2)}</h5>
                                    <h6>Per month</h6>
                                    <h5 className="mt-4"><strong>Number of Closet{count > 1 && "s"}: {count}</strong></h5>
                                    <ReactSlider
                                        value={count == 0 ? 1 : count}
                                        onChange={onChangeSlider}
                                        className="horizontal-slider"
                                        thumbClassName="slider-thumb"
                                        trackClassName="slider-track"
                                        max={5}
                                        min={1}
                                    />
                                    {!!alert && <Alert className="mt-2" toggle={() => setAlert('')} color='danger'>{alert}</Alert>}
                                    <LoadingButton onClick={onClkPayNow} disabled={count === 0 || upgrading} loading={upgrading}>
                                        <h6 className="mb-0">{getButtonTitle()}</h6>
                                    </LoadingButton>
                                    <Container>
                                        <div style={{ display: 'flex', gap: 10, justifyContent: 'center' }}>
                                            <input defaultChecked={readTerms} onChange={(e) => setreadTerms(e.target.checked)} type="checkbox" />
                                            <div style={{ fontSize: '14px' }}>
                                                <a href="https://poshsidekick.com/eula/" style={{ textDecoration: 'none', color: '#3A5579' }}>Terms of Service</a> and <a href="https://poshsidekick.com/privacy-policy/" style={{ textDecoration: 'none', color: '#3A5579' }}>Privacy Policy</a>
                                            </div>
                                        </div>
                                    </Container>
                                    <Container style={{ fontSize: "12px", marginTop: "5px", marginBottom: "5px", width: "90%" }}>
                                        All pricing is based in USD, countries other than USA are
                                        subject to conversion and exchange rates
                                    </Container>
                                    <Container style={{ marginTop: '15px' }}>
                                        <h5 style={{ color: '#3A5579', textAlign: 'start' }}>Main Features</h5>
                                        <Row xs={2}>
                                            <Col>
                                                {
                                                    firstColumn.map((feature, key) => {
                                                        return <h6 key={key} style={{ textAlign: 'start' }}>{feature}</h6>
                                                    })
                                                }

                                            </Col>
                                            <Col>
                                                {
                                                    secondColumn.map((feature, key) => {
                                                        return <h6 key={key} style={{ textAlign: 'start' }}>{feature}</h6>
                                                    })
                                                }

                                            </Col>

                                        </Row>
                                        <Col style={{ marginTop: '20px' }}>
                                            {
                                                secondRow.map((feature) => {
                                                    return <h6 style={{ textAlign: 'start' }}>{feature}</h6>
                                                })
                                            }

                                        </Col>
                                    </Container>
                                    <Container style={{ marginTop: '20px' }}>
                                        <h5 style={{ color: '#3A5579', textAlign: 'start' }}>Other Features</h5>

                                        <Col style={{ marginTop: '2px' }}>
                                            {
                                                otherFeatures.map((feature) => {
                                                    return <h6 style={{ textAlign: 'start' }}>{feature}</h6>
                                                })
                                            }

                                        </Col>
                                    </Container>
                                </CardBody>
                                <CardFooter>

                                </CardFooter>
                            </Card>
                            <Col className="mx-auto mt-2">
                                {
                                    subscriptionV2 && subscriptionV2.normalInvoice &&
                                    <CardSubscription userInfo={userInfo} header="Monthly Subscription" stripeSubscription={subscriptionV2} unsubscribeFunction={() => setShowModal(true)} />
                                }
                                {
                                    subscriptionV2 && subscriptionV2.promotedInvoice &&
                                    <CardSubscription userInfo={userInfo} header="Promoted Listings" stripeSubscription={subscriptionV2} unsubscribeFunction={() => setShowModalUnsubscribed(true)} />
                                }
                                <Col style={{ display: isMobile ? "block" : 'none' }}>
                                    {
                                        (paymentDetails && paymentDetails.length > 0 && userInfo) &&
                                        paymentDetails.map((payment) => {
                                            return <CardPayment addPayment={() => setOpenStripePayment(true)} userInfo={userInfo} paymentsDetail={payment} AllPaymentDetails={paymentDetails} />
                                        })
                                    }
                                    {
                                        <Button onClick={() => setOpenStripePayment(true)} style={{ backgroundColor: '#3A5579', width: '90%', marginTop: 15 }} className="mb-0">
                                            Add New Payment Method
                                        </Button>
                                    }
                                </Col>

                                {(userInfo && subscriptionV2 && subscriptionV2.normalInvoice) && <Coupon subscription={subscriptionV2.normalInvoice.subscription} userInfo={userInfo} />}
                            </Col>

                        </Col>
                    </Row>
                    {
                        !!openPaymentChooser &&
                        <ChoosePaymentModal
                            onClickStripe={() => setOpenStripePayment(true)}
                            onClickPaypal={() => setOpenPaypalPayment(true)}
                            isOpen={!!openPaymentChooser}
                            toggle={() => setOpenPaymentChooser(false)}
                            centered
                        />
                    }
                    {
                        !!openStripePayment &&
                        <StripePaymentModal isOpen={!!openStripePayment}
                            userId={userInfo?.id}
                            email={userInfo?.email}
                            phone={userInfo?.phone}
                            customerId={userInfo?.sCusId}
                            productId={getProductId()}
                            serverUrl={userInfo!.serverUrl!}
                            country={country}
                            toggle={() => {
                                setOpenStripePayment(false)
                            }}
                            onPaymentSuccess={onPaymentSuccess} />
                    }
                    {
                        !!openPaypalPayment &&
                        <PaypalPaymentModal isOpen={!!openPaypalPayment}
                            toggle={() => {
                                setOpenPaypalPayment(false)
                            }}
                            serverUrl={userInfo!.serverUrl!}
                            centered />
                    }
                </Col>
            </Row>

        </Elements>
    )
}

const CardSubscription = ({ stripeSubscription, unsubscribeFunction, header, userInfo }: { stripeSubscription: OverAllSubscription, unsubscribeFunction: () => void, header: string, userInfo: UserInfo | null }) => {
    const paymentHandler = new PaymentHandler(userInfo!.serverUrl!);
    const invoice = header.includes("Promoted") ? stripeSubscription.promotedInvoice : stripeSubscription.normalInvoice
    const createEpochTime = invoice.effective_at * 1000
    const dueDateAddMonth = new Date(createEpochTime);
    dueDateAddMonth.setMonth(dueDateAddMonth.getMonth() + 1);
    const dueDate = new Date(createEpochTime);
    const showPaynow = invoice.amount_remaining !== 0

    const [showAlertPaynow, setshowAlertPaynow] = useState(false)
    const [messagePaynow, setMessagePaynow] = useState<{ error: string | null, success: string | null }>({ error: null, success: null })
    const isPromoted = header.includes("Promoted")
    const { billingDetails } = usePoshSideKick()

    const payNormalSubscriptionBalance = async () => {
        if (billingDetails.length == 0) {
            setMessagePaynow({ error: "Sorry! You need to set a default payment method.", success: null })
            return;
        }
        if (
            !billingDetails.find(paymentMethod => paymentMethod.isDefault)
        ) {
            setMessagePaynow({ error: 'Please set a default payment method first.', success: null })
            return;
        }
        try {
            const userId = userInfo?.id as any
            await paymentHandler.payNowSubscription(userId, isPromoted ? "promoted" : "normal")
            setMessagePaynow({ error: null, success: 'Payment Success!' })
            setshowAlertPaynow(false)
        } catch (err: any) {
            const errorResponse = err?.response?.data?.message
            setMessagePaynow({ error: errorResponse ? errorResponse : "Something went wrong. Please try again later.", success: null })
            setshowAlertPaynow(false)
        }
    }


    return <Card body className="p-0 mt-2">
        <AlertModal
            title="Pay Now"
            body="You are about to pay your outstanding balance to resume your subscription."
            isOpen={showAlertPaynow}
            onClickPositive={() => payNormalSubscriptionBalance()}
            onClickNegative={() => setshowAlertPaynow(false)}
            errorMsg={messagePaynow.error ? messagePaynow.error : ""}
            succcessMsg={messagePaynow.success ? messagePaynow.success : ""}
        />
        <CardHeader> <strong>{header}</strong>  </CardHeader>
        <CardBody>
            <CardTitle tag="h5">
                <strong>
                    ${((invoice.amount_due as number) / 100).toFixed(2)}
                </strong>
            </CardTitle>
            <CardText>
                Renewal At {moment(showPaynow ? dueDate : dueDateAddMonth).format('MMM DD, YYYY')}
            </CardText>
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                {
                    showPaynow &&
                    <Button onClick={() => setshowAlertPaynow(true)} style={{ backgroundColor: '#3A5579', width: '90%' }} className="mb-2">
                        <h6 className="mb-0">Pay Now</h6>
                    </Button>

                }
                <Button onClick={unsubscribeFunction} style={{ backgroundColor: '#FF6D60', width: '90%' }}>
                    Unsubscribe
                </Button>
            </div>

        </CardBody>
    </Card>
}
const Coupon = ({ userInfo, subscription }: { userInfo: UserInfo, subscription: string }) => {
    const paymentHandler = new PaymentHandler(userInfo.serverUrl as string);
    const [code, setcode] = useState("")
    const [messageCoupon, setmessageCoupon] = useState<{ error: string | null, success: string | null }>({ error: null, success: null })

    const resetMessageCoupon = (time: number) => {
        setTimeout(() => setmessageCoupon({ success: null, error: null }), time * 1000)
    }
    const redeemCoupon = async () => {
        if (code.length == 0) {
            setmessageCoupon({ success: null, error: "Please enter a valid coupon code!" })
            resetMessageCoupon(3)
            return
        }
        try {
            await paymentHandler.getCouponDetails(code)
        } catch (e: any) {
            setmessageCoupon({ success: null, error: e.message })
            setcode("")
            resetMessageCoupon(3)
            return
        }
        try {
            await paymentHandler.applyCoupon(subscription, code)
            setmessageCoupon({ success: "Coupon applied successfully", error: null })
            resetMessageCoupon(3)
        } catch (error) {
            setmessageCoupon({ success: null, error: "Error occured. Please Try again" })
            resetMessageCoupon(3)
        }

    }
    return <Card body className="p-0 mt-2">
        <CardHeader> <strong>Coupon</strong>  </CardHeader>
        {
            messageCoupon.error &&
            <Alert color={"danger"}>
                {messageCoupon.error}
            </Alert>
        }
        {
            messageCoupon.success &&
            <Alert color={"danger"}>
                {messageCoupon.success}
            </Alert>
        }

        <CardBody>
            <input value={code} style={{ textAlign: "center", width: "100%" }} onChange={(e) => setcode(e.target.value)} type="text" placeholder="Coupon Code" className="coupon-input" />
            <div className='coupon-button'>
                <Button onClick={redeemCoupon} style={{ backgroundColor: '#FF6D60', width: '90%', marginTop: 10 }}>Redeem Coupon</Button>
            </div>
        </CardBody>
    </Card>
}

const CardPayment = ({ paymentsDetail, AllPaymentDetails, userInfo, addPayment }: { addPayment: () => void, userInfo: UserInfo, paymentsDetail: BillingDetailsInformation, AllPaymentDetails: Array<BillingDetailsInformation> }) => {
    const { card } = paymentsDetail
    const [showChangeDefaultCard, setshowChangeDefaultCard] = useState(false)
    const [showRemoveCard, setshowRemoveCard] = useState(false)
    const [messageRemoveCard, setMessageRemoveCard] = useState<{ error: string | null, success: string | null }>({ error: null, success: null })
    const [messageDefaultCard, setMessageDefaultCard] = useState<{ error: string | null, success: string | null }>({ error: null, success: null })
    const [cardCheckBox, setCardCheckBox] = useState(false)
    const paymentHandler = new PaymentHandler(userInfo.serverUrl ? userInfo.serverUrl : '');
    const removecard = async () => {
        try {
            await paymentHandler.removeCard(userInfo.id as number, paymentsDetail.id)
            setMessageRemoveCard({ error: null, success: "Payment method removed successfully!" })
            setTimeout(() => setshowRemoveCard(false), 3000)
        } catch (error: any) {
            setMessageRemoveCard({ error: "Sorry! Something went wrong with your request.", success: null })
            setTimeout(() => setshowRemoveCard(false), 3000)
        }
    }

    const setDefaultCard = async () => {
        setMessageDefaultCard({ error: null, success: null })
        try {
            await paymentHandler.defaultCard(userInfo.id as number, paymentsDetail.id)
            setMessageDefaultCard({ error: null, success: "Payment method set to default" })
            setTimeout(() => setshowChangeDefaultCard(false), 3000)
        } catch (error: any) {
            setMessageDefaultCard({ error: "Sorry! Something went wrong with your request.", success: null })
            setTimeout(() => setshowChangeDefaultCard(false), 3000)
        }

    }

    return (<Card style={{ maxHeight: "fit-content", width: "100%" }} className="p-0 mt-2">
        <AlertModal
            title="Change Default Card" body={"You are going to update the default card for monthly payments. Are you sure?"}
            isOpen={showChangeDefaultCard}
            errorMsg={messageDefaultCard.error ? messageDefaultCard.error : ""}
            succcessMsg={messageDefaultCard.success ? messageDefaultCard.success : ""}
            onClickPositive={() => setDefaultCard()}
            onClickNegative={() => {
                setCardCheckBox(false)
                setshowChangeDefaultCard(false)
            }}
        />
        <AlertModal
            title="Remove Card" body={"You are going to remove card. Are you sure?"}
            isOpen={showRemoveCard}
            errorMsg={messageRemoveCard.error ? messageRemoveCard.error : ""}
            succcessMsg={messageRemoveCard.success ? messageRemoveCard.success : ""}
            onClickPositive={() => removecard()}
            onClickNegative={() => {
                setshowRemoveCard(false)
            }}
        />
        <CardHeader style={{ backgroundColor: paymentsDetail.isDefault ? "blanchedalmond" : "white" }}>

            <strong>{upperFirst(card.brand)}</strong>
            {
                !paymentsDetail.isDefault &&
                AllPaymentDetails.find(i => i.isDefault) &&
                <BsFillTrashFill onClick={() => setshowRemoveCard(true)} color="red" style={{ position: "absolute", right: 10, top: 10 }} />
            }

        </CardHeader>
        <CardBody>
            <CardTitle tag="h5" style={{display: "flex", alignItems: "center", justifyContent: "center", flexDirection: "column"}}>

                <strong>
                    **** **** **** {card.last4}
                </strong>
                {
                    !paymentsDetail.isDefault && 
                    <div style={{display: "flex"}}>
                        <input checked={cardCheckBox} onChange={(value) => {
                                setshowChangeDefaultCard(value.target.checked)
                                setCardCheckBox(value.target.checked)
                            }} type="checkbox" style={{ marginRight: 15 }} />
                        <div style={{fontSize: "12px"}}>Mark as default</div>
                    </div>
 
                }
            </CardTitle>
            <CardText>
                {card.exp_month} /{' '}
                {card.exp_year}
            </CardText>
        </CardBody>
    </Card>)
}
export default Payment;