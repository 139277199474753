// import AuthResponse from "../interfaces/AuthResponse";
import APIHandler from "./APIHandler";
import validator from "validator";
import { Endpoints } from "../consts/Endpoints";
import ExceptionHandler from "./ExceptionHandler";
import parsePhoneNumberFromString from "libphonenumber-js/min";
import UserInfo from "../interfaces/UserInfo";

class AuthHandler extends ExceptionHandler {
    readonly apiHandler: APIHandler<UserInfo>;
    constructor() {
        super();
        this.apiHandler = new APIHandler(process.env.REACT_APP_SERVER_URL as string);
    }

    private validPhoneNumber = (phoneNumber?: string): boolean => {
        if (!phoneNumber) {
            return false;
        }
        
        const parsedNumber = parsePhoneNumberFromString(phoneNumber);
        if (!parsedNumber || !parsedNumber.isValid()) {
            return false;
        }
        return true;
    }

    private validEmail = (email?: string): boolean => {
        if (!email || !validator.isEmail(email)) {
            return false;
        }
        return true;
    }

    userRegister = async (userInfo: UserInfo) => {
        if (!userInfo.firstname || !userInfo.lastname) {
            throw new Error('Invalid name detected');
        }
        if (!this.validEmail(userInfo.email)) {
            throw new Error('Invalid email detected');
        }
        if (!userInfo.password) {
            throw new Error('Invalid password detected');
        }
        if (userInfo.password !== userInfo.confirmPassword) {
            throw new Error('Password does not match');
        }
        try {
            const user = await this.apiHandler.invokePOST(Endpoints.USER_REGISTER, userInfo)
            return user as UserInfo;
        } catch(e) {
            throw new Error(this.getUserFriendlyMessage(e));
        }
    }

    loginWithEmail = async (email?: string, password?: string) => {
        if (!this.validEmail(email)) {
            throw new Error('Invalid email detected');
        }
        if (!password) {
            throw new Error('Invalid password detected');
        }
        try {
            const user = await this.apiHandler.invokePOST(Endpoints.USER_LOGIN, { email, password })
            return user;
        } catch(e) {
            throw new Error(this.getUserFriendlyMessage(e));
        }
    }

    loginWithToken = async (token?: string) => {
        if (!token) {
            throw new Error('Invalid token detected');
        }
        try {
            const user = await this.apiHandler.invokePOST(Endpoints.USER_TOKEN_LOGIN, { token })
            return user;
        } catch(e) {
            throw new Error(this.getUserFriendlyMessage(e));
        }
    }

    sendSMS = async (phoneNumber?: string, ignoreDuplicateCheck?: boolean) => {
        if (!this.validPhoneNumber(phoneNumber)) {
            throw new Error('Invalid phone number detected');
        }
        try {
            const user = await this.apiHandler.invokePOST(Endpoints.SEND_SMS, { phone: phoneNumber, ignoreDuplicateCheck });
            return user;
        } catch(e) {
            throw new Error(this.getUserFriendlyMessage(e));
        }
    }

    sendEmailCode = async (email?: string, ignoreDuplicateCheck?: boolean) => {
        if (!this.validEmail(email)) {
            throw new Error('Invalid email detected');
        }
        try {
            const user = await this.apiHandler.invokePOST(Endpoints.SEND_SMS, { email, ignoreDuplicateCheck });
            return user;
        } catch(e) {
            throw new Error(this.getUserFriendlyMessage(e));
        }
    }

    submitSMSCode = async (inputCode: { first: string, second: string, third: string, fourth: string }, phoneNumber: string) => {
        if(!inputCode.first || !inputCode.second || !inputCode.third || !inputCode.fourth) {
            throw new Error('Invalid input code detected');
        }
        if (!this.validPhoneNumber(phoneNumber)) {
            throw new Error('Invalid phone number detected');
        }
        const code = inputCode.first + inputCode.second + inputCode.third + inputCode.fourth;
        try {
            await this.apiHandler.invokePOST(Endpoints.SUBMIT_VERIFY_CODE, { code, phone: phoneNumber })
        } catch(e) {
            throw new Error(this.getUserFriendlyMessage(e));
        }
    }

    submitEmailCode = async (inputCode: { first: string, second: string, third: string, fourth: string }, email: string) => {
        if(!inputCode.first || !inputCode.second || !inputCode.third || !inputCode.fourth) {
            throw new Error('Invalid input code detected');
        }
        if (!this.validEmail(email)) {
            throw new Error('Invalid email detected');
        }
        const code = inputCode.first + inputCode.second + inputCode.third + inputCode.fourth;
        try {
            await this.apiHandler.invokePOST(Endpoints.SUBMIT_VERIFY_CODE, { code, email })
        } catch(e) {
            throw new Error(this.getUserFriendlyMessage(e));
        }
    }

    loginWithPhoneNumber = async (phoneNumber?: string) => {
        if (!this.validPhoneNumber(phoneNumber)) {
            throw new Error('Invalid phone number detected');
        }
        try {
            const user = await this.apiHandler.invokePOST(Endpoints.USER_LOGIN, { email: phoneNumber });
            return user as UserInfo;
        } catch(e) {
            throw new Error(this.getUserFriendlyMessage(e));
        }
    }
}

export default AuthHandler;