import axios, { AxiosInstance } from 'axios';
import { Endpoints } from '../consts/Endpoints';
import PaymentIntent from '../interfaces/PaymentIntent';
import UserInfo from '../interfaces/UserInfo';
import PoshAccount from '../interfaces/PoshAccount';
import Subscription from '../interfaces/Subscription';
import Coupon from '../interfaces/Coupon';

type ResponseType = UserInfo | 
{
    user: UserInfo,
    accounts: [PoshAccount]
} | 
PaymentIntent |
Subscription | 
Coupon;

class APIHandler<T extends ResponseType> {
    readonly apiInstance: AxiosInstance;

    constructor(baseURL:string) {
        this.apiInstance = axios.create({
            baseURL,
            timeout: 30000
        });
    }

    invokePOST = async (endPoint: Endpoints, params?: Object | undefined) => {
        try {
            const resp = await this.apiInstance.post(endPoint, params);
            if (resp.data.success) {
                return resp.data.data as T;
            } else {
                throw new Error(resp.data.message ?? 'Unknown error detected');
            }
        } catch(e) {
            throw e;
        }
    }

    invokeGET = async (endPoint: Endpoints, params?: Object | undefined) => {
        try {
            const resp = await this.apiInstance.get(endPoint, { params });
            if (resp.data.success) {
                return resp.data.data as T;
            } else {
                throw new Error(resp.data.message ?? 'Unknown error detected');
            }
        } catch(e) {
            throw e;
        }
    }
}

export default APIHandler;