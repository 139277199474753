
import {
    Container,
    Card,
    CardBody,
    CardFooter,
    CardHeader,
    Row,
    Col,
    ButtonDropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    Button,
    Alert
} from 'reactstrap';
import { ReactComponent as Logo } from '../assets/logo_onboard.svg';
import { useRef, useState, useEffect } from 'react';
import LoadingButton from '../components/LoadingButton';
import WithPassword from '../components/login/WithPassword';
import WithCode from '../components/login/WithCode';
import AuthHandler from '../classes/AuthHandler';
import InputCodeModal from '../components/login/InputCodeModal';
import UserInfo from '../interfaces/UserInfo';
import { useLocation, useNavigate } from 'react-router-dom';

enum LOGIN_MODE {
    PASSWORD = 'With Password',
    // CODE = 'With Code'
}

type UserAuthType = { email?: string, password?: string, phoneNumber?: string } | undefined;

type AuthRefHandler = {
    getLoginInfo: () => UserAuthType
}

const Login = () => {
    const [alert, setAlert] = useState('');
    const [loggingIn, setLoggingIn] = useState(false);
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [activeMode, setActiveMode] = useState(LOGIN_MODE.PASSWORD);
    const [openCodeModal, setOpenCodeModal] = useState(false);
    const withPassRef = useRef<AuthRefHandler>(null);
    const withCodeRef = useRef<AuthRefHandler>(null);
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        const login = async (token: string) => {
            try {
                //mainly to use from app to directly open paypal
                const authHandler = new AuthHandler();
                const userInfo = await authHandler.loginWithToken(token);
                navigate('/payment?paypal=true', { state: userInfo })
            } catch (error) {
                console.log(error)
                setAlert("Error occured")
            }
        }
        if (location.search.includes("token")) {
            const token = location.search.replace(/.*token=/, "")
            if (token) login(token)
        }

    }, [navigate, location.search])


    const onLogin = async () => {
        try {
            let authParams: UserAuthType = undefined;
            const authHandler = new AuthHandler();
            let userInfo;
            setLoggingIn(true);
            if (activeMode === LOGIN_MODE.PASSWORD) {
                authParams = withPassRef.current?.getLoginInfo();
                userInfo = await authHandler.loginWithEmail(authParams?.email, authParams?.password);
                setLoggingIn(false);
                navigate('/payment', { state: userInfo })
            }
            // else if (activeMode === LOGIN_MODE.CODE) {
            //     authParams = withCodeRef.current?.getLoginInfo();
            //     await authHandler.sendSMS(authParams?.phoneNumber, true);
            //     setLoggingIn(false);
            //     setOpenCodeModal(true);
            // }
        } catch (e) {
            if (e instanceof Error) {
                setAlert(e.message);
            } else if (typeof e === 'string') {
                setAlert(e);
            }
            setLoggingIn(false);
        }
    }

    const onVerifySuccessWithCode = async () => {
        try {
            setOpenCodeModal(false);
            setLoggingIn(true);
            const authHandler = new AuthHandler();
            const phoneNumber = withCodeRef.current?.getLoginInfo()?.phoneNumber;
            if (phoneNumber) {
                const userInfo: UserInfo = await authHandler.loginWithPhoneNumber(phoneNumber);
                setLoggingIn(false);
                navigate('/payment', { state: userInfo });
            }
        } catch (e) {
            if (e instanceof Error) {
                setAlert(e.message);
            } else if (typeof e === 'string') {
                setAlert(e);
            }
        } finally {
            setLoggingIn(false);
        }
    }

    return (
        <Container className='my-5'>
            <Row>
                <Col lg='6' className='mx-auto'>
                    <Card>
                        <CardHeader>
                            <Logo />
                        </CardHeader>
                        <CardBody className='p-3'>
                            <h4><strong>Welcome Back</strong></h4>
                            <div className='d-flex justify-content-end'>
                                <ButtonDropdown isOpen={dropdownOpen} toggle={() => setDropdownOpen(prev => !prev)}>
                                    <DropdownToggle className='bg-transparent border-0 text-muted' caret>
                                        <small>{activeMode}</small>
                                    </DropdownToggle>
                                    <DropdownMenu>
                                        <DropdownItem onClick={(e) => setActiveMode(LOGIN_MODE.PASSWORD)}>
                                            <small>{LOGIN_MODE.PASSWORD}</small>
                                        </DropdownItem>
                                        {/* <DropdownItem divider /> */}
                                        {/* <DropdownItem onClick={(e) => setActiveMode(LOGIN_MODE.CODE)}>
                                            <small>{LOGIN_MODE.CODE}</small>
                                        </DropdownItem> */}
                                    </DropdownMenu>
                                </ButtonDropdown>
                            </div>
                            {
                                activeMode === LOGIN_MODE.PASSWORD &&
                                <WithPassword ref={withPassRef} />
                            }
                            {/* {
                                activeMode === LOGIN_MODE.CODE &&
                                <WithCode ref={withCodeRef} />
                            } */}
                            {
                                !!alert && <Alert className='mt-2' color='danger' toggle={() => setAlert('')}>{alert}</Alert>
                            }
                        </CardBody>
                        <CardFooter>
                            <LoadingButton onClick={onLogin} className='mt-2' loading={loggingIn} disabled={loggingIn}>
                                <h6 className='mb-0 text-white'>Login</h6>
                            </LoadingButton>
                            <div className='d-flex justify-content-center align-items-center'>
                                <small>Don't have an account?</small>
                                <Button color='link'><a href='https://poshsidekick.com/'>Sign Up</a></Button>
                            </div>
                        </CardFooter>
                    </Card>
                </Col>
            </Row>
            {
                openCodeModal &&
                <InputCodeModal isOpen={openCodeModal}
                    onVerifySuccess={onVerifySuccessWithCode}
                    phone={withCodeRef.current?.getLoginInfo()?.phoneNumber as string}
                    toggle={() => setOpenCodeModal(false)} />
            }
        </Container>
    )
}

export default Login;