import {
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Row,
    Col,
    Input,
    Alert
} from 'reactstrap';
import parsePhoneNumberFromString from "libphonenumber-js/min";
import styled from 'styled-components';
import React, { useRef, useState, useEffect } from 'react';
import LoadingButton from '../LoadingButton';
import AuthHandler from '../../classes/AuthHandler';

type Props = {
    isOpen: boolean,
    phone: string,
    toggle?: () => void,
    onVerifySuccess: () => void
}

const SingleInput = styled(Input)`
    width: 64px;
    height: 64px;
    font-size: 40px;
    font-weight: bold;  
`

const InputCodeModal = (props: Props) => {
    const [alert, setAlert] = useState('');
    const [submitting, setSubmitting] = useState(false);
    const [resending, setResending] = useState(false);
    const [inputCode, setInputCode] = useState({ first: '', second: '', third: '', fourth: '' })
    const firstRef = useRef<HTMLInputElement>(null);
    const secondRef = useRef<HTMLInputElement>(null);
    const thirdRef = useRef<HTMLInputElement>(null);
    const fourthRef = useRef<HTMLInputElement>(null);

    useEffect(() => {
        if (inputCode.first && inputCode.second && inputCode.third) {
            fourthRef.current?.focus();
            return;
        }
        if (inputCode.first && inputCode.second) {
            thirdRef.current?.focus();
            return;
        }
        if (inputCode.first) {
            secondRef.current?.focus();
            return;
        }
    }, [inputCode]);

    const resendSMSCode = async () => {
        try {
            setResending(true);
            const authHandler = new AuthHandler();
            await authHandler.sendSMS(props.phone, false);
        } catch(e) {
            if (e instanceof Error) {
                setAlert(e.message);
            } else if (typeof e === 'string') {
                setAlert(e);
            }
        } finally {
            setResending(false);
        }
    }

    const submitCode = async () => {
        try {
            setSubmitting(true);
            const authHandler = new AuthHandler();
            await authHandler.submitSMSCode(inputCode, props.phone);
            props.onVerifySuccess();
        } catch(e) {
            if (e instanceof Error) {
                setAlert(e.message);
            } else if (typeof e === 'string') {
                setAlert(e);
            }
        } finally {
            setSubmitting(false);
        }
    }

    return (
        <Modal isOpen={props.isOpen} toggle={props.toggle}>
            <ModalHeader className='justify-content-center'>
                We sent you an SMS code
            </ModalHeader>
            <ModalBody>
                <h6>On number: {props.phone ? parsePhoneNumberFromString(props.phone)?.formatInternational() : 'N/A'}</h6>
                <Row className='my-4'>
                    <Col className='d-flex justify-content-center'>
                        <SingleInput 
                            innerRef={firstRef} 
                            maxLength={1} 
                            value={inputCode.first} 
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => setInputCode(prev => ({ ...prev, first: e.target.value }))} />
                    </Col>
                    <Col className='d-flex justify-content-center'>
                        <SingleInput 
                            innerRef={secondRef} 
                            maxLength={1} 
                            value={inputCode.second} 
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => setInputCode(prev => ({ ...prev, second: e.target.value }))} />
                    </Col>
                    <Col className='d-flex justify-content-center'>
                        <SingleInput 
                            innerRef={thirdRef} 
                            maxLength={1} 
                            value={inputCode.third} 
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => setInputCode(prev => ({ ...prev, third: e.target.value }))} />
                    </Col>
                    <Col className='d-flex justify-content-center'>
                        <SingleInput 
                            innerRef={fourthRef} 
                            maxLength={1} 
                            value={inputCode.fourth} 
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => setInputCode(prev => ({ ...prev, fourth: e.target.value }))} />
                    </Col>
                </Row>
                <div className='d-flex justify-content-center align-items-center'>
                    <h6 className='mb-0'>Didn't get your code?</h6>
                    <LoadingButton color='link' 
                        className='py-0 bg-transparent w-auto' 
                        loading={resending} 
                        disabled={resending} 
                        onClick={resendSMSCode}>
                        <h6 className='mb-0'>Resend</h6>
                    </LoadingButton>
                </div>
                {
                    !!alert && <Alert className='mt-2' color='danger' toggle={() => setAlert('')}>{alert}</Alert>
                }
            </ModalBody>
            <ModalFooter className='justify-content-center'>
                <LoadingButton onClick={submitCode} loading={submitting} disabled={submitting}>
                    <h6 className='mb-0'>Submit</h6>
                </LoadingButton>
            </ModalFooter>
        </Modal>
    )
}

export default InputCodeModal;